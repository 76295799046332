@import 'styles/utils';

.wrapper {
  padding: 50px 0;
}

.image {
  height: auto;
  width: calc(100% - 10px);
  display: block;
  object-fit: contain;
}

.imageMarginDefault {
  margin-bottom: 30px;

  @include breakpoint('xs') {
    margin-bottom: 15px;
  }
  @include breakpoint('md') {
    margin: 0;
  }
}

.imageMarginCustom {
  margin: 0 auto 30px auto;

  @include breakpoint('xs') {
    margin: 0 auto 15px auto;
  }
  @include breakpoint('md') {
    margin: 0 auto;
  }
}

.title.title {
  margin-bottom: 15px;
  line-height: 32px;
  @include breakpoint('md') {
    line-height: 48px;
    margin-bottom: 20px;
  }
  @include breakpoint('xl') {
    margin-bottom: 20px;
    font-size: 48px;
    line-height: 56px;
  }
}

.subtitle.subtitle {
  line-height: 30px;
  @include breakpoint('md') {
    line-height: 32px;
  }
}

.markdown {
  margin-top: 15px;
  & p {
    line-height: 24px;
  }
  @include breakpoint('lg') {
    margin-top: 20px;
  }
}
